import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Languages, ArrowLeft, ArrowRight, Plus, Trash2 } from "lucide-react";
import { translations } from "../translations";
import * as yup from "yup";

const FormField = ({
  label,
  name,
  type = "text",
  register,
  error,
  options,
  description,
}) => {
  return (
    <div className="space-y-1">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {description && (
        <p className="text-sm text-gray-500 mb-2">{description}</p>
      )}
      {type === "select" ? (
        <select
          {...register(name)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "date" ? (
        <input
          type="date"
          {...register(name)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
        />
      ) : type === "textarea" ? (
        <textarea
          {...register(name)}
          rows={4}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
        />
      ) : (
        <input
          type={type}
          {...register(name)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
        />
      )}
      {error && <p className="text-red-500 text-sm">{error.message}</p>}
    </div>
  );
};

const DynamicTable = ({ title, fields, data, onAdd, onRemove }) => {
  const handleFieldChange = (index, fieldKey, value) => {
    const newData = [...data];
    newData[index] = {
      ...newData[index],
      [fieldKey]: value,
    };
    onAdd(newData);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">{title}</h3>
        <button
          type="button"
          onClick={() => onAdd([...data, {}])}
          className="flex items-center text-primary hover:opacity-80"
        >
          <Plus className="w-4 h-4 mr-1" />
          Add Row
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {field.label}
                </th>
              ))}
              <th className="px-4 py-2"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {data.map((row, index) => (
              <tr key={index}>
                {fields.map((field) => (
                  <td key={field.key} className="px-4 py-2">
                    {field.type === "date" ? (
                      <input
                        type="date"
                        value={row[field.key] || ""}
                        onChange={(e) =>
                          handleFieldChange(index, field.key, e.target.value)
                        }
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    ) : (
                      <input
                        type="text"
                        value={row[field.key] || ""}
                        onChange={(e) =>
                          handleFieldChange(index, field.key, e.target.value)
                        }
                        className="w-full p-1 border border-gray-300 rounded"
                      />
                    )}
                  </td>
                ))}
                <td className="px-4 py-2">
                  <button
                    type="button"
                    onClick={() => onRemove(index)}
                    className="text-gray-400 hover:text-red-500"
                  >
                    <Trash2 className="w-4 h-4" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const IntakeForm = ({ language, onLanguageChange }) => {
  const [step, setStep] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset: resetForm,
  } = useForm();
  const t = translations[language];

  const totalSteps = 16;

  const [previousAddresses, setPreviousAddresses] = useState([]);
  const [workHistory, setWorkHistory] = useState([]);
  const [travelHistory, setTravelHistory] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [educationHistory, setEducationHistory] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [previousMarriages, setPreviousMarriages] = useState([]);
  const [hasGuardian, setHasGuardian] = useState(false);

  const [data, setData] = useState({
    // ... other fields ...
    whereDidYouEnter: "", // Ensure this field is initialized
    // ... other fields ...
  });

  const schema = yup.object().shape({
    email: yup.string().email(t.invalidEmail).required(t.required),
    confirmEmail: yup
      .string()
      .oneOf([yup.ref("email")], t.emailMismatch)
      .required(t.required),
    phoneNumber: yup
      .string()
      .matches(/^[0-9-+() ]*$/, t.invalidPhoneNumber)
      .required(t.required),
  });

  const workFields = [
    { key: "from", label: t.from, type: "date" },
    { key: "to", label: t.to, type: "date" },
    { key: "jobTitle", label: t.jobTitle },
    { key: "sector", label: t.sector },
    { key: "company", label: t.company },
    { key: "address", label: t.address },
    { key: "unemployed", label: t.unemployed, type: "checkbox" },
  ];

  const maritalStatusOptions = [
    { value: "single", label: t.maritalStatusOptions.single },
    { value: "married", label: t.maritalStatusOptions.married },
    { value: "commonLaw", label: t.maritalStatusOptions.commonLaw },
    { value: "divorced", label: t.maritalStatusOptions.divorced },
    { value: "separated", label: t.maritalStatusOptions.separated },
    { value: "widowed", label: t.maritalStatusOptions.widowed },
  ];

  const newTranslations = {
    en: {
      invalidPhoneNumber: "Please enter a valid phone number",
      unemployed: "Unemployed during this period",
      passportInstructions:
        "Important: Please email copies of all your current and previous passports to info@abanorbert.ca",
      previousMarriageDetails: "Previous Marriage/Relationship Details",
    },
    fr: {
      invalidPhoneNumber: "Veuillez entrer un numéro de téléphone valide",
      unemployed: "Sans emploi pendant cette période",
      passportInstructions:
        "Important : Veuillez envoyer par courriel des copies de tous vos passeports actuels et précédents à info@abanorbert.ca",
      previousMarriageDetails: "Détails du mariage/de la relation précédente",
    },
  };

  const onSubmit = async (data) => {
    try {
      setSubmitting(true);
      setSubmitStatus(null);

      const formData = {
        ...data,
        whereDidYouEnter: data.whereDidYouEnter, 
        previousAddresses,
        workHistory,
        travelHistory,
        familyMembers,
        educationHistory,

        language,
      };

      console.log("Submitting form with data:", formData); // Debugging line
      console.log("Where did you enter:", data.whereDidYouEnter); // Debugging line

      const formattedMessage = `
New Immigration Form Submission

Language: ${language.toUpperCase()}
Submitted by: ${data.surname} ${data.givenName}

Basic Information:
----------------
Existing Immigration Claim: ${data.existingClaim || "Not provided"}

Personal Information:
-------------------
Surname: ${data.surname || "Not provided"}
Given Name: ${data.givenName || "Not provided"}
Date of Birth: ${data.dateOfBirth || "Not provided"}
Gender: ${data.gender || "Not provided"}
Height: ${data.height || "Not provided"}
Eye Color: ${data.eyeColor || "Not provided"}

Other Identities:
---------------
Used Other Names: ${data.otherNames || "Not provided"}
Used Other Date of Birth: ${data.otherDob || "Not provided"}

Current Address:
--------------
Street Number: ${data.streetNumber || "Not provided"}
Street Name: ${data.streetName || "Not provided"}
Apartment Number: ${data.apartmentNumber || "Not provided"}
City: ${data.city || "Not provided"}
Province: ${data.province || "Not provided"}
Postal Code: ${data.postalCode || "Not provided"}
Address Start Date: ${data.addressStartDate || "Not provided"}
Same Mailing Address: ${data.sameMailingAddress || "Not provided"}

Previous Addresses:
----------------
${
  previousAddresses
    .map(
      (addr, index) =>
        `Address ${index + 1}:
  From: ${addr.from || "Not provided"}
  To: ${addr.to || "Not provided"}
  Country: ${addr.country || "Not provided"}
  Street/City: ${addr.streetCity || "Not provided"}
  Status: ${addr.status || "Not provided"}`
    )
    .join("\n\n") || "No previous addresses provided"
}

Contact Information:
-----------------
Email: ${data.email || "Not provided"}
Email Confirmation: ${data.confirmEmail || "Not provided"}
Phone Type: ${data.phoneType || "Not provided"}
Phone Number: ${data.phoneNumber || "Not provided"}

Previous Marriages/Relationships:
-----------------------------
${
  previousMarriages
    .map(
      (marriage, index) =>
        `Marriage/Relationship ${index + 1}:
  Partner Name: ${marriage.partnerName || "Not provided"}
  Start Date: ${marriage.startDate || "Not provided"}
  End Date: ${marriage.endDate || "Not provided"}
  Type: ${marriage.type || "Not provided"}`
    )
    .join("\n\n") || "No previous marriages/relationships provided"
}

Work History (including unemployment periods):
-----------------------------------------
${
  workHistory
    .map(
      (work, index) =>
        `Period ${index + 1}:
  From: ${work.from || "Not provided"}
  To: ${work.to || "Not provided"}
  ${
    work.unemployed
      ? "Unemployed during this period"
      : `
  Job Title: ${work.jobTitle || "Not provided"}
  Sector: ${work.sector || "Not provided"}
  Company: ${work.company || "Not provided"}
  Address: ${work.address || "Not provided"}`
  }`
    )
    .join("\n\n") || "No work history provided"
}

Travel Information:
----------------
Arrival Method: ${data.arrivalMethod || "Not provided"}
Entry Point: ${data.entryPoint || "Not provided"}
Entry Date: ${data.entryDate || "Not provided"}
Current Status: ${data.currentStatus || "Not provided"}
Departure Date: ${data.departureDate || "Not provided"}
Departure Country: ${data.departureCountry || "Not provided"}
Departure City: ${data.departureCity || "Not provided"}
Departure Airport: ${data.departureAirport || "Not provided"}

Education and Work:
----------------
Post-Secondary Education: ${data.postSecondaryEducation || "Not provided"}

Education History:
${
  educationHistory
    .map(
      (edu, index) =>
        `Education ${index + 1}:
  School Name: ${edu.schoolName || "Not provided"}
  Start Date: ${edu.startDate || "Not provided"}
  End Date: ${edu.endDate || "Not provided"}
  Level: ${edu.level || "Not provided"}
  Field: ${edu.field || "Not provided"}
  Degree: ${edu.degree || "Not provided"}
  Country: ${edu.country || "Not provided"}
  City: ${edu.city || "Not provided"}`
    )
    .join("\n\n") || "No education history provided"
}

Travel History:
------------
Other Country Travel: ${data.otherCountryTravel || "Not provided"}
${
  travelHistory
    .map(
      (travel, index) =>
        `Travel ${index + 1}:
  From: ${travel.from || "Not provided"}
  To: ${travel.to || "Not provided"}
  Country: ${travel.country || "Not provided"}
  City/Province: ${travel.cityProvince || "Not provided"}
  Purpose: ${travel.purpose || "Not provided"}`
    )
    .join("\n\n") || "No travel history provided"
}
Visa Refusal: ${data.visaRefusal || "Not provided"}

Criminal and Medical History:
-------------------------
Criminal Record: ${data.criminalRecord || "Not provided"}
Arrests: ${data.arrests || "Not provided"}
Criminal Charges: ${data.criminalCharges || "Not provided"}
Criminal Acts: ${data.criminalActs || "Not provided"}
Organization Membership: ${data.organizationMembership || "Not provided"}
Violent Organizations: ${data.violentOrganizations || "Not provided"}
Criminal Organizations: ${data.criminalOrganizations || "Not provided"}
War Crimes: ${data.warCrimes || "Not provided"}
Violent Acts: ${data.violentActs || "Not provided"}
Medical Conditions: ${data.medicalConditions || "Not provided"}
Infectious Disease: ${data.infectiousDisease || "Not provided"}

Canada/US Visa Information:
------------------------
US Green Card: ${data.usGreenCard || "Not provided"}
Canadian Visitor: ${data.canadianVisitor || "Not provided"}
US Non-Immigrant: ${data.usNonImmigrant || "Not provided"}
Visa Application Date: ${data.visaApplication || "Not provided"}
Visa Issue Date: ${data.visaIssue || "Not provided"}
Visa Office: ${data.visaOffice || "Not provided"}
Visa Type: ${data.visaType || "Not provided"}

Military and Government Service:
----------------------------
Military Service: ${data.militaryService || "Not provided"}
Government Position: ${data.governmentPosition || "Not provided"}

Guardian Information:
------------------
Has Guardian: ${data.hasGuardian || "Not provided"}
${
  data.hasGuardian === "yes"
    ? `
Guardian Name: ${data.guardian?.guardianName || "Not provided"}
Guardian Relationship: ${data.guardian?.guardianRelationship || "Not provided"}
Guardian Address: ${data.guardian?.guardianAddress || "Not provided"}
Guardian Phone: ${data.guardian?.guardianPhone || "Not provided"}
Guardian Email: ${data.guardian?.guardianEmail || "Not provided"}`
    : `
Guardian Name: Not provided
Guardian Relationship: Not provided
Guardian Address: Not provided
Guardian Phone: Not provided
Guardian Email: Not provided`
}

Family Members:
----------------
${
  familyMembers
    .map(
      (member, index) =>
        `Family Member ${index + 1}:
  Family Name: ${member.familyName || "Not provided"}
  Given Name: ${member.givenName || "Not provided"}
  Relationship: ${member.relationship || "Not provided"}
  Date of Birth: ${member.dateOfBirth || "Not provided"}
  Citizenship: ${member.citizenship || "Not provided"}
  Location: ${member.location || "Not provided"}
  Deceased: ${member.deceased || "Not provided"}`
    )
    .join("\n\n") || "No family members provided"
}
`;

      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          access_key: "20c6b638-7994-4a55-9a6f-a1fe23803d92",
          // 20c6b638-7994-4a55-9a6f-a1fe23803d92
          // 3d3397fc-dfc9-4861-8243-d4e0ecd344ac
          subject: `Immigration Form Submission - ${data.surname}, ${data.givenName}`,
          from_name: `${data.surname} ${data.givenName}`,
          message: formattedMessage,
          email: data.email || "info@abanorbert.ca",
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.message || "Failed to submit form");
      }

      if (result.success) {
        setSubmitStatus({
          type: "success",
          message: t.formSubmissionSuccess,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        throw new Error(result.message || t.formSubmissionError);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setSubmitStatus({
        type: "error",
        message: error.message || t.formSubmissionError,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const resetFieldsForStep = (currentStep) => {
    const fieldsToReset = {};

    switch (currentStep) {
      case 1:
        fieldsToReset.existingClaim = "";
        break;
      case 2:
        fieldsToReset.surname = "";
        fieldsToReset.givenName = "";
        fieldsToReset.dateOfBirth = "";
        fieldsToReset.gender = "";
        fieldsToReset.height = "";
        fieldsToReset.eyeColor = "";
        fieldsToReset.otherNames = "";
        fieldsToReset.otherDob = "";
        break;
      case 3:
        fieldsToReset.streetNumber = "";
        fieldsToReset.streetName = "";
        fieldsToReset.apartmentNumber = "";
        fieldsToReset.city = "";
        fieldsToReset.province = "";
        fieldsToReset.postalCode = "";
        fieldsToReset.addressStartDate = "";
        fieldsToReset.sameMailingAddress = "";
        break;
      case 4:
        setPreviousAddresses([]);
        break;
      case 5:
        fieldsToReset.email = "";
        fieldsToReset.confirmEmail = "";
        fieldsToReset.phoneType = "";
        fieldsToReset.phoneNumber = "";
        break;
      case 6:
        fieldsToReset.nativeLanguage = "";
        fieldsToReset.interviewLanguage = "";
        fieldsToReset.contactLanguage = "";
        break;
      case 7:
        fieldsToReset.hadPreviousMarriage = "";
        setPreviousMarriages([]);
        break;
      case 8:
        fieldsToReset.hasGuardian = "";
        break;
      case 9:
        setFamilyMembers([]);
        break;
      case 10:
        fieldsToReset.arrivalMethod = "";
        fieldsToReset.entryPoint = "";
        fieldsToReset.entryDate = "";
        fieldsToReset.currentStatus = "";
        fieldsToReset.departureDate = "";
        fieldsToReset.departureCountry = "";
        fieldsToReset.departureCity = "";
        fieldsToReset.departureAirport = "";
        fieldsToReset.otherCountryTravel = "";
        break;
      case 11:
        fieldsToReset.postSecondaryEducation = "";
        setEducationHistory([]);
        break;
      case 12:
        fieldsToReset.militaryService = "";
        fieldsToReset.governmentPosition = "";
        break;
      case 13:
        setWorkHistory([]);
        break;
      case 14:
        fieldsToReset.criminalRecord = "";
        fieldsToReset.arrests = "";
        fieldsToReset.criminalCharges = "";
        fieldsToReset.criminalActs = "";
        fieldsToReset.organizationMembership = "";
        fieldsToReset.violentOrganizations = "";
        fieldsToReset.criminalOrganizations = "";
        fieldsToReset.warCrimes = "";
        fieldsToReset.violentActs = "";
        fieldsToReset.medicalConditions = "";
        fieldsToReset.infectiousDisease = "";
        break;
      case 15:
        fieldsToReset.usGreenCard = "";
        fieldsToReset.canadianVisitor = "";
        fieldsToReset.usNonImmigrant = "";
        fieldsToReset.visaApplication = "";
        fieldsToReset.visaIssue = "";
        fieldsToReset.visaOffice = "";
        fieldsToReset.visaType = "";
        break;
    }

    resetForm({
      ...watch(),
      ...fieldsToReset,
    });
  };

  const nextStep = () => {
    const newStep = Math.min(step + 1, totalSteps);
    setStep(newStep);
    resetFieldsForStep(newStep);
  };

  const prevStep = () => {
    const newStep = Math.max(step - 1, 1);
    setStep(newStep);
  };

  const yesNoOptions = [
    { value: "", label: t.choose },
    { value: "yes", label: t.yes },
    { value: "no", label: t.no },
  ];

  const educationFields = [
    { key: "schoolName", label: t.schoolName },
    { key: "startDate", label: t.startDate, type: "date" },
    { key: "endDate", label: t.endDate, type: "date" },
    { key: "level", label: t.level },
    { key: "field", label: t.field },
    { key: "degree", label: t.degree },
  ];

  const previousMarriageFields = [
    { key: "partnerName", label: t.spouseName },
    { key: "startDate", label: t.marriageDate, type: "date" },
    { key: "endDate", label: t.relationshipEndDate, type: "date" },
    {
      key: "type",
      label: t.maritalStatus,
      type: "select",
      options: maritalStatusOptions,
    },
  ];

  const guardianFields = [
    { key: "guardianName", label: t.guardianName },
    { key: "guardianRelationship", label: t.guardianRelationship },
    { key: "guardianAddress", label: t.guardianAddress },
    { key: "guardianPhone", label: t.guardianPhone },
    { key: "guardianEmail", label: t.guardianEmail },
  ];

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <div className="mb-6">
              <p className="text-sm text-gray-600 mb-4">{t.formConfirmation}</p>
              <p className="text-sm text-gray-600">{t.formInstructions}</p>
            </div>
            <h2 className="text-xl font-semibold mb-4">{t.basicInfo}</h2>
            <FormField
              label={t.existingClaim}
              name="existingClaim"
              type="select"
              register={register}
              error={errors.existingClaim}
              options={yesNoOptions}
            />
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.personalInfo}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                label={t.surname}
                name="surname"
                register={register}
                error={errors.surname}
              />
              <FormField
                label={t.givenName}
                name="givenName"
                register={register}
                error={errors.givenName}
              />
              <FormField
                label={t.dateOfBirth}
                name="dateOfBirth"
                type="date"
                register={register}
                error={errors.dateOfBirth}
              />
              <FormField
                label={t.gender}
                name="gender"
                type="select"
                register={register}
                error={errors.gender}
                options={[
                  { value: "", label: t.choose },
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                  { value: "other", label: "Other" },
                ]}
              />
              <FormField
                label={t.height}
                name="height"
                type="number"
                register={register}
                error={errors.height}
              />
              <FormField
                label={t.eyeColor}
                name="eyeColor"
                register={register}
                error={errors.eyeColor}
              />
            </div>
            <div className="mt-6">
              <h3 className="text-lg font-medium mb-4">{t.otherIdentities}</h3>
              <div className="space-y-4">
                <FormField
                  label={t.otherNames}
                  name="otherNames"
                  type="select"
                  register={register}
                  error={errors.otherNames}
                  options={yesNoOptions}
                />
                <FormField
                  label={t.otherDob}
                  name="otherDob"
                  type="select"
                  register={register}
                  error={errors.otherDob}
                  options={yesNoOptions}
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.currentAddress}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormField
                label={t.streetNumber}
                name="streetNumber"
                register={register}
                error={errors.streetNumber}
              />
              <FormField
                label={t.streetName}
                name="streetName"
                register={register}
                error={errors.streetName}
              />
              <FormField
                label={t.apartmentNumber}
                name="apartmentNumber"
                register={register}
                error={errors.apartmentNumber}
              />
              <FormField
                label={t.city}
                name="city"
                register={register}
                error={errors.city}
              />
              <FormField
                label={t.province}
                name="province"
                register={register}
                error={errors.province}
              />
              <FormField
                label={t.postalCode}
                name="postalCode"
                register={register}
                error={errors.postalCode}
              />
            </div>
            <FormField
              label={t.addressStartDate}
              name="addressStartDate"
              type="date"
              register={register}
              error={errors.addressStartDate}
            />
            <FormField
              label={t.sameMailingAddress}
              name="sameMailingAddress"
              type="select"
              register={register}
              error={errors.sameMailingAddress}
              options={yesNoOptions}
            />
          </div>
        );
      case 4:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">
              {t.previousAddresses}
            </h2>
            <DynamicTable
              title={t.previousAddressesTitle}
              fields={[
                { key: "from", label: t.from, type: "date" },
                { key: "to", label: t.to, type: "date" },
                { key: "country", label: t.country },
                { key: "streetCity", label: t.streetCity },
                { key: "status", label: t.status },
              ]}
              data={previousAddresses}
              onAdd={setPreviousAddresses}
              onRemove={(index) => {
                const newAddresses = [...previousAddresses];
                newAddresses.splice(index, 1);
                setPreviousAddresses(newAddresses);
              }}
            />
          </div>
        );
      case 5:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.contactInfo}</h2>
            <FormField
              label={t.email}
              name="email"
              type="email"
              register={register}
              error={errors.email}
            />
            <FormField
              label={t.confirmEmail}
              name="confirmEmail"
              type="email"
              register={register}
              error={errors.confirmEmail}
            />
            <FormField
              label={t.phoneType}
              name="phoneType"
              type="select"
              register={register}
              error={errors.phoneType}
              options={[
                { value: "", label: t.choose },
                { value: "mobile", label: t.mobile },
                { value: "home", label: t.home },
                { value: "work", label: t.work },
              ]}
            />
            <FormField
              label={t.phoneNumber}
              name="phoneNumber"
              register={register}
              error={errors.phoneNumber}
            />
          </div>
        );
      case 6:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.languageInfo}</h2>
            <FormField
              label={t.nativeLanguage}
              name="nativeLanguage"
              register={register}
              error={errors.nativeLanguage}
            />
            <FormField
              label={t.interviewLanguage}
              name="interviewLanguage"
              register={register}
              error={errors.interviewLanguage}
            />
            <FormField
              label={t.contactLanguage}
              name="contactLanguage"
              type="select"
              register={register}
              error={errors.contactLanguage}
              options={[
                { value: "", label: t.choose },
                { value: "en", label: "English" },
                { value: "fr", label: "Français" },
              ]}
            />
            <div className="mt-4 p-4 bg-gray-50 rounded-md">
              <h3 className="font-medium mb-2">{t.travelDocument}</h3>
              <p className="text-sm text-gray-600">{t.travelDocumentNote}</p>
            </div>
          </div>
        );
      case 7:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">
              {t.previousMarriageDetails}
            </h2>
            <FormField
              label={t.previousMarriage}
              name="hadPreviousMarriage"
              type="select"
              register={register}
              error={errors.hadPreviousMarriage}
              options={yesNoOptions}
            />
            {watch("hadPreviousMarriage") === "yes" && (
              <DynamicTable
                title={t.previousMarriageDetails}
                fields={previousMarriageFields}
                data={previousMarriages}
                onAdd={setPreviousMarriages}
                onRemove={(index) => {
                  const newMarriages = [...previousMarriages];
                  newMarriages.splice(index, 1);
                  setPreviousMarriages(newMarriages);
                }}
              />
            )}
          </div>
        );
      case 8:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.guardianInfo}</h2>
            <FormField
              label={t.needGuardian}
              name="hasGuardian"
              type="select"
              register={register}
              error={errors.hasGuardian}
              options={yesNoOptions}
              onChange={(e) => setHasGuardian(e.target.value === "yes")}
            />
            {watch("hasGuardian") === "yes" && (
              <div className="space-y-4">
                {guardianFields.map((field) => (
                  <FormField
                    key={field.key}
                    label={field.label}
                    name={`guardian.${field.key}`}
                    register={register}
                    error={errors.guardian?.[field.key]}
                  />
                ))}
              </div>
            )}
          </div>
        );
      case 9:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.familyMembers}</h2>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <p className="text-sm font-medium text-gray-700">
                {t.familyMembersOrder}
              </p>
              <ul className="list-disc list-inside text-sm text-gray-600 mt-2">
                <li>{t.familyMembersOrderSpouse}</li>
                <li>{t.familyMembersOrderChildren}</li>
                <li>{t.familyMembersOrderParents}</li>
                <li>{t.familyMembersOrderSiblings}</li>
              </ul>
              <p className="text-sm text-gray-600 mt-2">
                {t.familyMembersDeceased}
              </p>
            </div>
            <DynamicTable
              title={t.familyMembersTitle}
              fields={[
                { key: "familyName", label: t.familyName },
                { key: "givenName", label: t.givenNames },
                { key: "relationship", label: t.relationshipToYou },
                { key: "dateOfBirth", label: t.dateOfBirth, type: "date" },
                { key: "citizenship", label: t.citizenship },
                { key: "location", label: t.location },
                { key: "deceased", label: t.deceasedStatus, type: "date" },
              ]}
              data={familyMembers}
              onAdd={setFamilyMembers}
              onRemove={(index) => {
                const newMembers = [...familyMembers];
                newMembers.splice(index, 1);
                setFamilyMembers(newMembers);
              }}
            />
          </div>
        );
      case 10:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.travelInfo}</h2>
            <FormField
              label={t.arrivalMethod}
              name="arrivalMethod"
              type="select"
              register={register}
              error={errors.arrivalMethod}
              options={[
                { value: "", label: t.choose },
                { value: "air", label: "Air" },
                { value: "land", label: "Land" },
                { value: "sea", label: "Sea" },
              ]}
            />
            <FormField
              label={t.entryPoint}
              name="entryPoint"
              register={register}
              error={errors.entryPoint}
            />
            <FormField
              label={t.entryDate}
              name="entryDate"
              type="date"
              register={register}
              error={errors.entryDate}
            />
            <FormField
              label={t.currentStatus}
              name="currentStatus"
              type="select"
              register={register}
              error={errors.currentStatus}
              options={[
                { value: "", label: t.choose },
                { value: "visitor", label: "Visitor" },
                { value: "student", label: "Student" },
                { value: "worker", label: "Worker" },
                { value: "refugee", label: "Refugee Claimant" },
                { value: "other", label: "Other" },
              ]}
            />
            <FormField
              label={t.departureDate}
              name="departureDate"
              type="date"
              register={register}
              error={errors.departureDate}
            />
            <FormField
              label={t.departureCountry}
              name="departureCountry"
              register={register}
              error={errors.departureCountry}
            />
            <FormField
              label={t.departureCity}
              name="departureCity"
              register={register}
              error={errors.departureCity}
            />
            <FormField
              label={t.departureAirport}
              name="departureAirport"
              register={register}
              error={errors.departureAirport}
            />
            <FormField
              label={t.otherCountryTravel}
              name="otherCountryTravel"
              type="select"
              register={register}
              error={errors.otherCountryTravel}
              options={yesNoOptions}
            />
            <DynamicTable
              title={t.travelHistoryTitle}
              fields={[
                { key: "from", label: t.from, type: "date" },
                { key: "to", label: t.to, type: "date" },
                { key: "country", label: t.country },
                { key: "cityProvince", label: t.cityProvince },
                { key: "purpose", label: t.purpose },
              ]}
              data={travelHistory}
              onAdd={setTravelHistory}
              onRemove={(index) => {
                const newHistory = [...travelHistory];
                newHistory.splice(index, 1);
                setTravelHistory(newHistory);
              }}
            />
            <FormField
              label={t.visaRefusal}
              name="visaRefusal"
              type="select"
              register={register}
              error={errors.visaRefusal}
              options={yesNoOptions}
            />
          </div>
        );
      case 11:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.educationWork}</h2>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <p className="text-sm text-gray-600">{t.educationInstructions}</p>
            </div>
            <FormField
              label={t.postSecondaryEducation}
              name="postSecondaryEducation"
              type="select"
              register={register}
              error={errors.postSecondaryEducation}
              options={yesNoOptions}
            />
            <DynamicTable
              title={t.educationHistoryTitle}
              fields={educationFields}
              data={educationHistory}
              onAdd={setEducationHistory}
              onRemove={(index) => {
                const newHistory = [...educationHistory];
                newHistory.splice(index, 1);
                setEducationHistory(newHistory);
              }}
            />
          </div>
        );
      case 12:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">
              {t.militaryServiceTitle}
            </h2>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <p className="text-sm text-gray-600">
                {t.militaryServiceDescription}
              </p>
            </div>
            <FormField
              label={t.militaryService}
              name="militaryService"
              type="select"
              register={register}
              error={errors.militaryService}
              options={yesNoOptions}
            />

            <h2 className="text-xl font-semibold mb-4 mt-8">
              {t.governmentPositionsTitle}
            </h2>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <p className="text-sm text-gray-600">
                {t.governmentPositionDescription}
              </p>
            </div>
            <FormField
              label={t.governmentPosition}
              name="governmentPosition"
              type="select"
              register={register}
              error={errors.governmentPosition}
              options={yesNoOptions}
            />
          </div>
        );
      case 13:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.workHistoryTitle}</h2>
            <div className="bg-gray-50 p-4 rounded-md mb-6">
              <p className="text-sm text-gray-600">
                {t.workHistoryInstructions}
              </p>
              <p className="text-sm text-gray-600 mt-2">{t.workHistoryGaps}</p>
            </div>
            <DynamicTable
              title={t.workHistoryTitle}
              fields={workFields}
              data={workHistory}
              onAdd={setWorkHistory}
              onRemove={(index) => {
                const newHistory = [...workHistory];
                newHistory.splice(index, 1);
                setWorkHistory(newHistory);
              }}
            />
          </div>
        );
      case 14:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.criminalMedical}</h2>
            <div className="space-y-4">
              <FormField
                label={t.criminalRecord}
                name="criminalRecord"
                type="select"
                register={register}
                error={errors.criminalRecord}
                options={yesNoOptions}
              />
              <FormField
                label={t.arrests}
                name="arrests"
                type="select"
                register={register}
                error={errors.arrests}
                options={yesNoOptions}
              />
              <FormField
                label={t.criminalCharges}
                name="criminalCharges"
                type="select"
                register={register}
                error={errors.criminalCharges}
                options={yesNoOptions}
              />
              <FormField
                label={t.criminalActs}
                name="criminalActs"
                type="select"
                register={register}
                error={errors.criminalActs}
                options={yesNoOptions}
              />
              <FormField
                label={t.organizationMembership}
                name="organizationMembership"
                type="select"
                register={register}
                error={errors.organizationMembership}
                options={yesNoOptions}
              />
              <FormField
                label={t.violentOrganizations}
                name="violentOrganizations"
                type="select"
                register={register}
                error={errors.violentOrganizations}
                options={yesNoOptions}
              />
              <FormField
                label={t.criminalOrganizations}
                name="criminalOrganizations"
                type="select"
                register={register}
                error={errors.criminalOrganizations}
                options={yesNoOptions}
              />
              <FormField
                label={t.warCrimes}
                name="warCrimes"
                type="select"
                register={register}
                error={errors.warCrimes}
                options={yesNoOptions}
              />
              <FormField
                label={t.violentActs}
                name="violentActs"
                type="select"
                register={register}
                error={errors.violentActs}
                options={yesNoOptions}
              />
              <FormField
                label={t.medicalConditions}
                name="medicalConditions"
                type="select"
                register={register}
                error={errors.medicalConditions}
                options={yesNoOptions}
              />
              <FormField
                label={t.infectiousDisease}
                name="infectiousDisease"
                type="select"
                register={register}
                error={errors.infectiousDisease}
                options={yesNoOptions}
              />
            </div>
          </div>
        );
      case 15:
        return (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold mb-4">{t.canadaVisaInfo}</h2>
            <FormField
              label={t.usGreenCard}
              name="usGreenCard"
              type="select"
              register={register}
              error={errors.usGreenCard}
              options={yesNoOptions}
            />
            <FormField
              label={t.canadianVisitor}
              name="canadianVisitor"
              type="select"
              register={register}
              error={errors.canadianVisitor}
              options={yesNoOptions}
            />
            <FormField
              label={t.usNonImmigrant}
              name="usNonImmigrant"
              type="select"
              register={register}
              error={errors.usNonImmigrant}
              options={yesNoOptions}
            />
            <FormField
              label={t.visaApplication}
              name="visaApplication"
              type="date"
              register={register}
              error={errors.visaApplication}
            />
            <FormField
              label={t.visaIssue}
              name="visaIssue"
              type="date"
              register={register}
              error={errors.visaIssue}
            />
            <FormField
              label={t.visaOffice}
              name="visaOffice"
              register={register}
              error={errors.visaOffice}
            />
            <FormField
              label={t.visaType}
              name="visaType"
              type="select"
              register={register}
              error={errors.visaType}
              options={[
                { value: "", label: t.choose },
                { value: "visitor", label: "Visitor" },
                { value: "student", label: "Student" },
                { value: "work", label: "Work" },
                { value: "permanent", label: "Permanent Resident" },
                { value: "other", label: "Other" },
              ]}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#00000008] to-white py-8 px-4">
      {submitStatus && (
        <div
          className={`fixed top-10 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg shadow-lg z-50 ${
            submitStatus.type === "success" ? "bg-green-600" : "bg-red-600"
          }`}
        >
          <span className="text-white font-medium">{submitStatus.message}</span>
        </div>
      )}

      <div className="max-w-5xl mt-20 mx-auto bg-white rounded-lg shadow-md p-6">
        <div className="flex justify-between items-center mb-8">
          <div>
            <h1 className=" text-2xl font-bold text-gray-800">{t.formTitle}</h1>
            <p className="mt-2 text-sm text-gray-600">{t.formDescription}</p>
            <p className="mt-2 text-sm text-gray-600">{t.formDisclaimer}</p>
          </div>
          <button
            onClick={() => onLanguageChange(language === "en" ? "fr" : "en")}
            className="flex items-center text-primary hover:opacity-80"
          >
            <Languages className="w-5 h-5 mr-2" />
            {language === "en" ? "Français" : "English"}
          </button>
        </div>

        <div className="mb-6">
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-primary h-2 rounded-full transition-all duration-300"
              style={{ width: `${(step / totalSteps) * 100}%` }}
            />
          </div>
          <div className="text-center mt-2 text-sm text-gray-600">
            {t.step} {step} {t.of} {totalSteps}
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {renderFormStep()}

          <div className="flex justify-between mt-8">
            {step > 1 && (
              <button
                type="button"
                onClick={prevStep}
                className="flex items-center px-4 py-2 text-primary hover:bg-gray-50 rounded-md"
                disabled={submitting}
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                {t.previous}
              </button>
            )}

            {step < totalSteps ? (
              <button
                type="button"
                onClick={nextStep}
                className="flex items-center px-4 py-2 bg-primary text-white rounded-md ml-auto hover:opacity-90 transition-colors"
                disabled={submitting}
              >
                {t.next}
                <ArrowRight className="w-4 h-4 ml-2" />
              </button>
            ) : (
              <button
                type="submit"
                className="flex items-center px-4 py-2 bg-primary text-white rounded-md ml-auto hover:opacity-90 transition-colors"
                disabled={submitting}
              >
                {submitting ? "Submitting..." : t.submit}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
