export const translations = {
    en: {
      formTitle: 'Client Intake Form',
      formDescription: 'This form is designed to collect the necessary information to complete your immigration application.',
      formConfirmation: 'By completing and returning this form, you:\n• confirm that all information provided is true and accurate to the best of your knowledge\n• acknowledge that providing false or misleading information may result in inadmissibility to Canada.',
      formInstructions: 'Please ensure the form is completed in its entirety before submitting it to our office via email at info@abanorbert.ca.',
      step: 'Step',
      of: 'of',
      previous: 'Previous',
      next: 'Next',
      submit: 'Submit',
      formSubmissionSuccess: 'Form submitted successfully!',
      formSubmissionError: 'Error submitting form. Please try again.',
      
      // Step 1: Basic Information
      basicInfo: 'New or Existing Refugee Claim',
      existingClaim: 'Did you already make a claim to an officer when you entered Canada (for example: land border, airport)?',
      
      // Step 2: Personal Information
      personalInfo: 'Personal Information',
      surname: 'Surname or last name',
      givenName: 'Given name or first name',
      dateOfBirth: 'Date of birth',
      gender: 'Gender',
      height: 'Height (in centimeters)',
      eyeColor: 'Eye colour',
      otherIdentities: 'Other Identities',
      otherNames: 'Have you used other names before?',
      otherDob: 'Have you used another date of birth before?',
      
      // Step 3: Current Address
      currentAddress: 'Current Address in Canada',
      streetNumber: 'Street number',
      streetName: 'Street name',
      apartmentNumber: 'Apartment or unit number',
      city: 'City or town',
      province: 'Province',
      postalCode: 'Postal code',
      addressStartDate: 'Date you started living at this address',
      sameMailingAddress: 'Is your mailing address the same as your residential address?',
      
      // Step 4: Previous Addresses
      previousAddresses: 'Previous Addresses',
      previousAddressesTitle: 'Previous Addresses for the Past 10 Years',
      from: 'From',
      to: 'To',
      country: 'Country',
      streetCity: 'Street & City',
      status: 'Status',
      
      // Step 5: Contact Information
      contactInfo: 'Contact Information',
      email: 'Email address',
      confirmEmail: 'Confirm your email address',
      phoneType: 'Telephone type',
      phoneCountry: 'Telephone number country or territory',
      phoneNumber: 'Telephone numbers',
      mobile: 'Mobile',
      home: 'Home',
      work: 'Work',
      
      // Step 6: Language and Travel Document
      languageInfo: 'Language Information',
      nativeLanguage: 'What is your native language or mother tongue?',
      interviewLanguage: 'What language do you want us to use during your interview?',
      contactLanguage: 'What language do you want us to use to contact you?',
      travelDocument: 'Travel Document',
      travelDocumentNote: 'Please email copies of your current and past passports to info@abanorbert.ca',
      
      // Step 7: Family Information
      familyInfo: 'Family Information',
      maritalStatus: 'What is your current marital status?',
      previousMarriage: 'Have you been married or in a common-law relationship before?',
      spouseInfo: 'Spouse/Common Law Partner Information',
      spouseName: 'First and Last Name of your Spouse/Common Law Partner (current or ex)',
      spouseDob: 'Their Date of Birth',
      spouseDeceaseDate: 'Decease Date (If applicable)',
      spouseAddress: 'Current Address',
      marriageDate: 'Date of Marriage or Date Common Law Relationship Started',
      relationshipEndDate: 'Date Relationship Ended (If applicable)',
      guardianInfo: 'Guardian Information',
      needGuardian: 'Do you have a guardian because you can\'t understand the proceedings or you\'re under 18 years of age?',
      guardianName: 'Guardian\'s Name',
      guardianRelationship: 'Relationship to Guardian',
      guardianAddress: 'Guardian\'s Address',
      guardianPhone: 'Guardian\'s Phone Number',
      guardianEmail: 'Guardian\'s Email',
      
      // Step 8: Family Members
      familyMembers: 'Family Members',
      familyMembersTitle: 'List of Family Members',
      familyMembersNote: 'List your relatives, living or deceased, in the order below. If deceased, please put decease date for the address:\n1. Your spouse(s), or your common-law partner, including your same-sex partner\n2. Your children, including children born outside of marriage and adopted children\n3. Your parents\n4. Your brothers and sisters, including half-brothers and half-sisters',
      familyName: 'Family/Last Name',
      givenNames: 'Given Names',
      relationship: 'Relationship',
      citizenship: 'Citizenship',
      location: 'Country and City where they live',
      
      // Step 9: Travel and Immigration
      travelInfo: 'Travel to Canada',
      arrivalMethod: 'How did you arrive in Canada?',
      entryPoint: 'Where did you enter Canada?',
      entryDate: 'When did you most recently enter Canada?',
      currentStatus: 'What is your current status in Canada?',
      departureDate: 'Date you left your previous country of residence',
      departureCountry: 'Country or territory you left',
      departureCity: 'City or town',
      departureAirport: 'Airport you flew out of',
      
      // Step 10: Education and Work
      educationWork: 'Education, Work and Other Activities',
      postSecondaryEducation: 'Are you currently studying or have you ever studied at a post-secondary school?',
      educationHistoryTitle: 'Education History',
      schoolName: 'School or institution name',
      startDate: 'Date studies started',
      endDate: 'Date studies ended',
      level: 'Level of Studies',
      field: 'Field of Studies',
      degree: 'Did you get a Degree',
      workHistoryTitle: 'Work History',
      workHistoryNote: 'You must enter every job or activity since the age of 18. Don\'t leave gaps in your history. If you enter time spent unemployed, make sure the months you enter don\'t overlap with months when you were employed or in another activity.',
      jobTitle: 'Job title',
      sector: 'Employment Sector',
      company: 'Company',
      address: 'Address (City and Country)',
      
      // Military and Government
      militaryService: 'Did you serve in any military, militia, civil defence unit, security organization or police force?',
      governmentPosition: 'Have you ever held a government position?',
      governmentPositionNote: '(A government position could be a civil servant, judge, mayor, Member of Parliament, hospital administrator, or employee of a security organization.)',
      
      // Step 11: Travel History
      travelHistory: 'Travel History',
      otherCountryTravel: 'Since the age of 18, have you travelled to a country or territory other than where you live now or where you\'re a citizen?',
      travelHistoryTitle: 'Travel History Details',
      cityProvince: 'City & Province',
      purpose: 'Purpose',
      visaRefusal: 'Has Canada or any other country ever refused to issue you a visa or permit, denied you entry to the country, or ordered you to leave?',
      
      // Step 12: Criminal and Medical History
      criminalMedical: 'Criminal and Medical History',
      criminalRecord: 'Have you ever been convicted of a crime in any country or territory?',
      arrests: 'Have you ever been arrested or detained in any country or territory?',
      criminalCharges: 'Have you ever been charged, sought, or wanted for a crime in any country or territory?',
      criminalActs: 'Have you ever committed a crime in any country or territory?',
      organizationMembership: 'Have you supported, been a member of, or been associated with any organizations?',
      violentOrganizations: 'Have you supported, been a member of, or been associated with any organizations that uses, used, advocated or advocates the use of armed struggle or violence to reach political, religious, or social objectives?',
      criminalOrganizations: 'Have you been a member of any organization that is or was engaged in criminal activity?',
      warCrimes: 'Have you ever witnessed or participated in the ill treatment of prisoners or civilians, looting or desecration of religious buildings?',
      violentActs: 'Have you ever used, planned, or advocated the use of armed struggle or violence to reach political, social or religious objectives?',
      medicalConditions: 'Have you ever had any serious disease, or physical or mental disorder',
      infectiousDisease: 'Do you currently have any infectious diseases?',
      
      // Canada and US Visas
      usGreenCard: 'Are you a lawful permanent resident of the United States (U.S.) with a valid green card (alien registration card)?',
      canadianVisitor: 'Have you held a Canadian visitor visa in the past 10 years?',
      usNonImmigrant: 'Do you currently hold a valid U.S. non-immigrant visa?',
      visaApplication: 'Date you submitted Visa application',
      visaIssue: 'Date Visa was issued',
      visaOffice: 'Name of the office that issued the Visa',
      visaType: 'Visa Type',
      
      // Options
      yes: 'Yes',
      no: 'No',
      choose: 'Choose an option',
      
      // Validation Messages
      required: 'This field is required',
      invalidEmail: 'Please enter a valid email address',
      emailMismatch: 'Email addresses do not match',
      invalidDate: 'Please enter a valid date',
      invalidNumber: 'Please enter a valid number',
      
      // Education Instructions
      educationInstructions: "Please provide details of all post-secondary education, including any current studies.",
      
      // Military and Government Service
      militaryServiceTitle: "Military and Police History",
      militaryServiceDescription: "Please provide details about any service in military, security, or police forces.",
      governmentPositionsTitle: "Government Positions",
      governmentPositionDescription: "A government position could be a civil servant, judge, mayor, Member of Parliament, hospital administrator, or employee of a security organization.",
      
      // Work History
      workHistoryInstructions: "You must enter every job or activity since the age of 18. Don't leave gaps in your history.",
      workHistoryGaps: "If you enter time spent unemployed, make sure the months you enter don't overlap with months when you were employed or in another activity. For example, if you were unemployed between January and March, then started a job in March, enter your time unemployed as between January and February.",
      
      // Family Members
      familyMembersOrder: "List your relatives in the following order:",
      familyMembersOrderSpouse: "1. Your spouse(s), or your common-law partner, including your same-sex partner",
      familyMembersOrderChildren: "2. Your children, including children born outside of marriage and adopted children",
      familyMembersOrderParents: "3. Your parents",
      familyMembersOrderSiblings: "4. Your brothers and sisters, including half-brothers and half-sisters",
      familyMembersDeceased: "For deceased family members, please indicate the date of death in the address field.",
      
      // Additional Fields
      deceasedStatus: "Deceased (If applicable)",
      currentResidence: "Current Place of Residence",
      relationshipToYou: "Relationship to You",
      maritalStatusOptions: {
        single: "Single",
        married: "Married",
        commonLaw: "Common Law",
        divorced: "Divorced",
        separated: "Separated",
        widowed: "Widowed"
      }
    },
    fr: {
      formTitle: "Formulaire d'admission du client",
      formDescription: "Ce formulaire est conçu pour recueillir les informations nécessaires pour compléter votre demande d'immigration.",
      formDisclaimer: "En remplissant et en renvoyant ce formulaire, vous confirmez que tous les renseignements fournis sont véridiques et exacts au meilleur de votre connaissance et reconnaissez que la fourniture de renseignements faux ou trompeurs peut entraîner l'interdiction de territoire au Canada.",
      step: 'Étape',
      of: 'sur',
      previous: 'Précédent',
      next: 'Suivant',
      submit: 'Soumettre',
      formSubmissionSuccess: 'Formulaire soumis avec succès!',
      formSubmissionError: 'Erreur lors de la soumission du formulaire. Veuillez réessayer.',
      
      // Basic Information
      basicInfo: "Demande d'asile nouvelle ou existante",
      existingClaim: "Avez-vous déjà présenté une demande d'asile à un agent lorsque vous êtes entré au Canada (par exemple : frontière terrestre, aéroport) ?",
      
      // Personal Information
      personalInfo: 'Renseignements personnels',
      surname: 'Nom ou nom de famille',
      givenName: 'Prénom',
      dateOfBirth: 'Date de naissance',
      gender: 'Genre',
      height: 'Hauteur (en centimètres)',
      eyeColor: 'Couleur des yeux',
      otherIdentities: "Avez-vous déjà utilisé d'autres identités ?",
      otherNames: "Avez-vous déjà utilisé d'autres noms ?",
      otherDob: 'Avez-vous déjà utilisé une autre date de naissance ?',
      
      // Current Address
      currentAddress: 'Adresse actuelle au Canada',
      streetNumber: 'Numéro de rue',
      streetName: 'Nom de la rue',
      apartmentNumber: "Numéro d'appartement ou d'unité",
      city: 'Ville ou village',
      province: 'Province',
      postalCode: 'Code postal',
      addressStartDate: 'Date à laquelle vous avez commencé à habiter à cette adresse',
      sameMailingAddress: 'Votre adresse postale est-elle la même que votre adresse résidentielle ?',
      
      // Previous Addresses
      previousAddresses: 'Adresses précédentes',
      previousAddressesTitle: 'Adresses précédentes depuis 10 ans',
      from: 'De',
      to: 'À',
      country: 'Pays',
      streetCity: 'Rue et ville',
      status: 'Statut',
      
      // Contact Information
      contactInfo: 'Coordonnées',
      email: 'Adresse courriel',
      confirmEmail: 'Confirmez votre adresse e-mail',
      phoneType: 'Type de téléphone',
      phoneCountry: 'Numéro de téléphone, pays ou territoire',
      phoneNumber: 'Numéros de téléphone',
      mobile: 'Mobile',
      home: 'Domicile',
      work: 'Travail',
      
      // Language and Travel Document
      languageInfo: 'Langue',
      nativeLanguage: 'Quelle est votre langue maternelle ?',
      interviewLanguage: 'Quel langage souhaitez-vous que nous utilisions lors de votre interview ?',
      contactLanguage: 'Quelle langue souhaitez-vous que nous utilisions pour vous contacter ?',
      travelDocument: 'Document de voyage',
      travelDocumentNote: 'Veuillez envoyer par courriel des copies de vos passeports actuels et antérieurs à info@abanorbert.ca',
      
      // Family Information
      familyInfo: 'Renseignements sur la famille',
      maritalStatus: 'Quel est votre état civil actuel ?',
      previousMarriage: 'Avez-vous déjà été marié ou en union de fait ?',
      spouseInfo: 'Informations sur le conjoint/conjoint de fait',
      spouseName: 'Prénom et nom de votre époux ou conjoint de fait (actuel ou ancien)',
      spouseDob: 'Leur date de naissance',
      spouseDeceaseDate: 'Date de décès (le cas échéant)',
      spouseAddress: 'Adresse actuelle',
      marriageDate: "Date du mariage ou date du début de l'union de fait",
      relationshipEndDate: 'Date de fin de la relation (le cas échéant)',
      guardianInfo: 'Informations sur le tuteur',
      needGuardian: 'Avez-vous un tuteur parce que vous ne comprenez pas la procédure ou que vous avez moins de 18 ans ?',
      guardianName: 'Nom du tuteur',
      guardianRelationship: 'Lien avec le tuteur',
      guardianAddress: 'Adresse du tuteur',
      guardianPhone: 'Numéro de téléphone du tuteur',
      guardianEmail: 'Courriel du tuteur',
      
      // Family Members
      familyMembers: 'Membres de la famille',
      familyMembersTitle: 'Liste des membres de la famille',
      familyMembersNote: 'Listez vos proches, vivants ou décédés, dans l\'ordre ci-dessous. Si décédé, veuillez indiquer la date de décès pour l\'adresse :\n1. Votre conjoint(s) ou conjoint de fait, y compris votre partenaire de même sexe\n2. Vos enfants, y compris enfants nés hors mariage et enfants adoptés\n3. Vos parents\n4. Vos frères et sœurs, y compris frères et sœurs de moitié',
      familyName: 'Nom de famille',
      givenNames: 'Prénoms',
      relationship: 'Relation',
      citizenship: 'Citoyenneté',
      location: 'Pays et ville où ils vivent',
      
      // Travel and Immigration
      travelInfo: 'Voyager au Canada',
      arrivalMethod: 'Comment êtes-vous arrivé au Canada ?',
      entryPoint: 'Où êtes-vous entré au Canada ?',
      entryDate: 'Quand êtes-vous entré au Canada pour la dernière fois ?',
      currentStatus: 'Quelle est votre situation actuelle au Canada ?',
      departureDate: 'Date à laquelle vous avez quitté votre pays de résidence précédent',
      departureCountry: 'Pays ou territoire que vous avez quitté',
      departureCity: 'Ville ou village',
      departureAirport: 'Aéroport de départ',
      
      // Education and Work
      educationWork: 'Études, travail et autres activités',
      postSecondaryEducation: "Étudiez-vous actuellement ou avez-vous déjà étudié dans un établissement d'enseignement postsecondaire ?",
      educationHistoryTitle: "Historique de l'éducation",
      schoolName: "Nom de l'école ou de l'institution",
      startDate: 'Date de début des études',
      endDate: 'Date de fin des études',
      level: "Niveau d'études",
      field: "Domaine d'études",
      degree: 'Avez-vous obtenu un diplôme',
      workHistoryTitle: 'Historique de travail',
      workHistoryNote: 'Vous devez entrer chaque emploi ou activité depuis l\'âge de 18 ans. Ne laissez pas de vides dans votre historique. Si vous entrez le temps passé sans emploi, assurez-vous que les mois que vous entrez ne se chevauchent pas avec les mois où vous étiez employé ou en activité.',
      jobTitle: 'Titre du poste',
      sector: "Secteur de l'emploi",
      company: 'Compagnie',
      address: 'Adresse (ville et pays)',
      
      // Military and Government
      militaryService: 'Avez-vous servi dans une armée, une milice, une unité de défense civile, une organisation de sécurité ou une force de police ?',
      governmentPosition: 'Avez-vous déjà occupé un poste gouvernemental ?',
      governmentPositionNote: "(Un poste gouvernemental peut être un fonctionnaire, un juge, un maire, un député, un administrateur d'hôpital ou un employé d'une organisation de sécurité.)",
      
      // Travel History
      travelHistory: 'Historique des voyages',
      otherCountryTravel: "Depuis l'âge de 18 ans, avez-vous voyagé dans un pays ou un territoire autre que celui où vous vivez actuellement ou dont vous êtes citoyen ?",
      travelHistoryTitle: 'Détails des voyages',
      cityProvince: 'Ville et province',
      purpose: 'But',
      visaRefusal: "Le Canada ou tout autre pays vous a-t-il déjà refusé un visa ou un permis, vous a refusé l'entrée au pays ou vous a ordonné de partir ?",
      
      // Criminal and Medical History
      criminalMedical: 'Antécédents criminels et médicaux',
      criminalRecord: 'Avez-vous déjà été condamné pour un crime dans un pays ou un territoire ?',
      arrests: 'Avez-vous déjà été arrêté ou détenu dans un pays ou un territoire ?',
      criminalCharges: 'Avez-vous déjà été accusé, recherché ou recherché pour un crime dans un pays ou un territoire ?',
      criminalActs: 'Avez-vous déjà commis un crime dans un pays ou un territoire ?',
      organizationMembership: 'Avez-vous soutenu, été membre ou été associé à des organisations ?',
      violentOrganizations: "Avez-vous soutenu, été membre ou été associé à des organisations qui utilisent, ont utilisé, préconisent ou ont préconisé l'utilisation de la lutte armée ou de la violence pour atteindre des objectifs politiques, religieux ou sociaux ?",
      criminalOrganizations: "Avez-vous été membre d'une organisation qui est ou était engagée dans des activités criminelles ?",
      warCrimes: "Avez-vous déjà été témoin ou participé à de mauvais traitements infligés à des prisonniers ou à des civils, à des pillages ou à la profanation d'édifices religieux ?",
      violentActs: "Avez-vous déjà utilisé, planifié ou préconisé l'utilisation de la lutte armée ou de la violence pour atteindre des objectifs politiques, sociaux ou religieux ?",
      medicalConditions: "Avez-vous déjà souffert d'une maladie grave ou d'un trouble physique ou mental",
      infectiousDisease: 'Avez-vous actuellement des maladies infectieuses ?',
      
      // Canada and US Visas
      usGreenCard: 'Êtes-vous un résident permanent légal des États-Unis avec une carte verte valide ?',
      canadianVisitor: 'Avez-vous détenu un visa de visiteur canadien au cours des 10 dernières années ?',
      usNonImmigrant: 'Détenez-vous actuellement un visa de non-immigrant américain valide ?',
      visaApplication: 'Date de soumission de la demande de visa',
      visaIssue: 'Date de délivrance du visa',
      visaOffice: 'Nom du bureau qui a délivré le visa',
      visaType: 'Type de visa',
      
      // Options
      yes: 'Oui',
      no: 'Non',
      choose: 'Choisissez une option',
      
      // Validation Messages
      required: 'Ce champ est obligatoire',
      invalidEmail: 'Veuillez entrer une adresse e-mail valide',
      emailMismatch: 'Les adresses e-mail ne correspondent pas',
      invalidDate: 'Veuillez entrer une date valide',
      invalidNumber: 'Veuillez entrer un numéro valide',
      
      // Education Instructions
      educationInstructions: "Veuillez fournir les détails de toutes les études postsecondaires, y compris les études en cours.",
      
      // Military and Government Service
      militaryServiceTitle: "Histoire militaire et policière",
      militaryServiceDescription: "Veuillez fournir des détails sur tout service dans les forces militaires, de sécurité ou de police.",
      governmentPositionsTitle: "Postes gouvernementaux",
      governmentPositionDescription: "Un poste gouvernemental peut être un fonctionnaire, un juge, un maire, un député, un administrateur d'hôpital ou un employé d'une organisation de sécurité.",
      
      // Work History
      workHistoryInstructions: "Vous devez entrer chaque emploi ou activité depuis l'âge de 18 ans. Ne laissez pas de vides dans votre historique.",
      workHistoryGaps: "Si vous entrez une période de chômage, assurez-vous que les mois saisis ne chevauchent pas les mois où vous étiez employé ou dans une autre activité. Par exemple, si vous étiez au chômage entre janvier et mars, puis avez commencé un emploi en mars, entrez votre période de chômage entre janvier et février.",
      
      // Family Members
      familyMembersOrder: "Listez vos proches dans l'ordre suivant :",
      familyMembersOrderSpouse: "1. Votre/vos conjoint(s) ou conjoint de fait, y compris votre partenaire de même sexe",
      familyMembersOrderChildren: "2. Vos enfants, y compris les enfants nés hors mariage et les enfants adoptés",
      familyMembersOrderParents: "3. Vos parents",
      familyMembersOrderSiblings: "4. Vos frères et sœurs, y compris les demi-frères et demi-sœurs",
      familyMembersDeceased: "Pour les membres de la famille décédés, veuillez indiquer la date de décès dans le champ adresse.",
      
      // Additional Fields
      deceasedStatus: "Décédé (le cas échéant)",
      currentResidence: "Lieu de résidence actuel",
      relationshipToYou: "Lien de parenté avec vous",
      maritalStatusOptions: {
        single: "Célibataire",
        married: "Marié(e)",
        commonLaw: "Union de fait",
        divorced: "Divorcé(e)",
        separated: "Séparé(e)",
        widowed: "Veuf/Veuve"
      }
    }
  };