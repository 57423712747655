import React, { useState } from "react";
import { LanguageSelector } from "../Components/LanguageSelector";
import { IntakeForm } from "../Components/InTakeForm";

const Form = () => {
  const [language, setLanguage] = useState(null);

  if (!language) {
    return <LanguageSelector onLanguageSelect={setLanguage} />;
  }

  return <IntakeForm language={language} onLanguageChange={setLanguage} />;
};

export default Form;
