import React from "react";
import "../Styles/Footer.css";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div>
      <section className="ej-min-h-70vh ej-w-full ej-text-white" id="header">
        <section className="ej-w-full ej-slide ej-min-h-70vh ej-mt-100px">
          <div className="ej-w-full ej-slide-content-container ej-mx-auto ej-flex ej-items-center ej-justify-start ej-py-8">
            {/* Text Section */}
            <div className="ej-container ej-px-3 ej-py-5 ej-mx-auto ej-min-h-318px">
              <section className="ej-w-full ej-flex ej-flex-col ej-gap-8 ej-pl-18px ej-border-white ej-min-h-318px ej-whitespace-normal lg:ej-w-1090px cat">
                <h2 className="ej-heading-title ej-size-defaul text-white">
                  Ready To Enhance Your Canadian Journey?
                </h2>
                <p className="ej-heading-title ej-size-default ej-subtitle text-gray-100 mt-5 leading-normal">
                  Take the first step towards your Canadian journey and book an
                  Appointment today for personalized immigration guidance and
                  support.
                </p>
                <div className="ej-button-wrapper ej-bg-main-color mt-8">
                  <a
                    className="ej-button ej-bg-main-color ej-button-link ej-size-lg ej-shadow-md"
                    href="/book-appointment"
                  >
                    <span className="ej-button-content-wrapper">
                      <span className="ej-button-text">
                        Book Online Appointment
                      </span>
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>
      <div className="relative mt-16 bg-gray-900">
        <svg
          className="absolute top-0 w-full h-6 -mt-5 sm:-mt-10 sm:h-16 text-gray-900"
          preserveAspectRatio="none"
          viewBox="0 0 1440 54"
        >
          <path
            fill="currentColor"
            d="M0 22L120 16.7C240 11 480 1.00001 720 0.700012C960 1.00001 1200 11 1320 16.7L1440 22V54H1320C1200 54 960 54 720 54C480 54 240 54 120 54H0V22Z"
          />
        </svg>
        <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="grid grid-cols-2 min-[690px]:grid-cols-4 lg:grid-cols-6 gap-4 xl:gap-8 pt-14 pb-10 max-w-xs mx-auto min-[690px]:max-w-2xl lg:max-w-full">
            <div className="col-span-full mb-10 lg:col-span-2 lg:mb-0">
              <a href="/" className="flex justify-center lg:justify-start">
                <div className="flex items-center">
                  <img
                    src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/abanlogo_wcfhdm"
                    loading="lazy"
                    alt="logo"
                    className="w-25 h-12 mr-2"
                  />
                </div>
              </a>
              <p className="py-8 text-gray-100 lg:max-w-xs text-center lg:text-left">
                Ready to start your refugee claim? Contact AbaNorbert
                Immigration Services today for a confidential consultation. Our
                team is here to listen, understand your unique circumstances,
                and provide the guidance you need.
              </p>
              <a
                href="/contact-us"
                className="p-2 font-semibold block w-fit bg-primary shadow-sm text-md text-white mx-auto transition-all  duration-500  lg:mx-0"
              >
                Contact us
              </a>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-primary font-medium mb-7">
                Useful links
              </h4>
              <ul className="text-md  transition-all duration-500">
                <li className="mb-6">
                  <a
                    href="/"
                    className="text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Home
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/about-us"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    About
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/services"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Services
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/contact-us"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Contact us
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/pay-now"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Pay now
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-primary font-medium mb-7">
                Our services
              </h4>
              <ul className="text-md transition-all duration-500">
                <li className="mb-6">
                  <a
                    href="/services/refugee-claims"
                    className="text-gray-200 hover:text-primary"
                  >
                    Refugee Claims
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/services/refugee-appeal"
                    className=" text-gray-200 hover:text-primary"
                  >
                    Refugee Appeal
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/services/express-entry"
                    className=" text-gray-200 hover:text-primary"
                  >
                    Express Entry
                  </a>
                </li>

                <li className="mb-6">
                  <a
                    href="/services/pre-removal-risk-assessment"
                    className=" text-gray-200 hover:text-primary"
                  >
                    Pre-Removal Risk Assessment
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="/services/humanitarian-and-compassionate-consideration"
                    className=" text-gray-200 hover:text-primary"
                  >
                    Humanitarian and Compassionate
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-primary font-medium mb-7">
                Other services
              </h4>
              <ul className="text-md transition-all duration-500">
                <li className="mb-6">
                  <a
                    href="javascript:;"
                    className="text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Work Permits
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="javascript:;"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Study Permits
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="javascript:;"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Visa Applications
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:mx-auto text-left ">
              <h4 className="text-lg text-primary font-medium mb-7">
                Resources
              </h4>
              <ul className="text-md  transition-all duration-500">
                <li className="mb-6">
                  <a
                    href="javascript:;"
                    className="text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    FAQ
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="javascript:;"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Cookies
                  </a>
                </li>
                <li className="mb-6">
                  <a
                    href="intake-form"
                    className=" text-gray-200 whitespace-nowrap hover:text-primary"
                  >
                    Intake Form
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col justify-between pt-5 pb-10 border-t border-primary sm:flex-row">
            <p className="text-sm text-gray-100">
              © Copyright {year} AbaNorbert Inc. All rights reserved.
            </p>
            <div className="flex items-center mt-4 space-x-4 sm:mt-0">
              <a
                href="https://x.com/Abanorberts"
                className="transition-colors duration-300 text-white hover:text-primary"
              >
                <svg
                  viewBox="0 0 1024 1024"
                  fill="currentColor"
                  className="h-5"
                >
                  <path d="M517.729 543.899L849.347 987.476 642.821 987.476 435.399 707.635 214.138 987.476 70.886 987.476 437.052 534.659 78.396 36.524 284.922 36.524 490.419 327.765 694.927 36.524 837.799 36.524z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/abanorbertimmigration/"
                className="transition-colors duration-300 text-white hover:text-primary"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61555828269284"
                className="transition-colors duration-300 text-white hover:text-primary"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default Footer;
