import React from 'react';
import { Languages } from 'lucide-react';

export const LanguageSelector = ({ onLanguageSelect }) => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-[#00000008] to-white flex items-center justify-center p-4">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <div className="flex items-center justify-center mb-6">
          <Languages className="w-12 h-12 text-primary" />
        </div>
        
        <h1 className="text-2xl font-bold text-center mb-8">
          Select Your Language / Choisissez votre langue
        </h1>
        
        <div className="space-y-4">
          <button
            onClick={() => onLanguageSelect('en')}
            className="w-full py-3 px-4 bg-primary text-white rounded-lg hover:opacity-90 transition-colors"
          >
            English
          </button>
          
          <button
            onClick={() => onLanguageSelect('fr')}
            className="w-full py-3 px-4 bg-primary text-white rounded-lg hover:opacity-90 transition-colors"
          >
            Français
          </button>
        </div>
      </div>
    </div>
  );
};