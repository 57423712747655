import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useMediaQuery } from "react-responsive";
import features from "../utils/features";
import testimonials from "../utils/testimonials";
import Aos from "aos";
import Footer from "../Components/Footer";
import "aos/dist/aos.css";
import "../Styles/Home.css";
import services from "../utils/Services";
import { Faq } from "../Components/Faq";
import canada01 from "../assets/canada01.jpg";
import canada02 from "../assets/canada02.jpg";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const [startIndex, setStartIndex] = useState(0);

  const handlePrev = () => {
    setStartIndex(
      (prevIndex) => (prevIndex - 1 + services.length) % services.length
    );
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const displayedServices = services
    .slice(startIndex, startIndex + 3)
    .concat(services.slice(0, Math.max(0, startIndex + 3 - services.length)));

  useEffect(() => {
    Aos.init({ duration: 100 });
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div>
      <Helmet>
        <title>AbaNorbert Immigration Services | Home</title>
        <meta name="description" content="At AbaNorbertNorbert Immigration Services Inc., we believe in providing our clients with access to quality immigration assistance." />
        <link rel="canonical" href="/" />
      </Helmet>
      <div className="relative">
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <div className="carousel-container absolute inset-0 z-0">
            <Carousel
              showArrows={false}
              autoPlay={true}
              interval={5000}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              swipeable={!isMobile}
              className="carousel-custom-height"
            >
              <div className="relative h-full">
                <img
                  src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canada01_lz81my"
                  loading="lazy"
                  className="object-cover h-full w-full Image"
                />
                <div className="absolute inset-0 bg-black opacity-65"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
                  <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-center max-w-xxl">
                    Navigating Borders, Building Futures
                  </h1>

                  <p className="mt-6 text-lg leading-8 text-center max-w-md mx-auto">
                    At our core, we're committed to guiding you through every
                    step of your immigration journey, ensuring it's not only
                    smooth but also profoundly successful.
                  </p>
                  <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4 sm:gap-y-0">
                    <Link
                      to={"/book-appointment"}
                      className="bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Book Now
                    </Link>
                    <Link
                      to={"/about-us"}
                      className="text-sm font-semibold leading-6 text-white"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative h-full">
                <img
                  src="https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_2000/canada02_1_ggjqsy"
                  loading="lazy"
                  className="object-cover h-full w-full Image"
                />
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="absolute inset-0 flex flex-col items-center justify-center text-white p-4">
                  <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight text-center max-w-xxl">
                    You will never walk alone
                  </h1>
                  <p className="mt-6 text-lg leading-8 text-center max-w-md mx-auto">
                    We're dedicated to understanding the intricacies of your
                    unique situation and providing personalized solutions that
                    precisely match your objectives.
                  </p>
                  <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-x-6 gap-y-4 sm:gap-y-0">
                    <a
                      href="/book-appointment"
                      className="bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Book Now
                    </a>
                    <a
                      href="/about-us"
                      className="text-sm font-semibold leading-6 text-white"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>

      <section className="services-section">
        <div className="w-full min-h-[90vh] bg-white" id="services">
          <div
            className="mx-auto max-w-2xl text-center py-10"
            data-aos="fade-up"
            data-aos-duration="300"
          >
            <h2 className="text-base font-semibold leading-7 text-primary">
              What we do
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our professional Services
            </p>
          </div>

          <div className="max-w-screen-xl mx-auto mt-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {displayedServices.map((item, index) => (
                <div
                  key={index}
                  className="rounded overflow-hidden flex flex-col transform transition-transform duration-300 hover:scale-105"
                >
                  <a href={item.link}>
                    <img
                      className="w-full h-80 object-cover"
                      src={item.imgSrc}
                      alt={item.title}
                      loading="lazy"
                    />
                  </a>
                  <div className="relative text-center -mt-16 pt-5 pb-12 bg-white m-10 shadow-lg rounded-lg">
                    <a
                      href={item.link}
                      className="font-semibold text-lg inline-block hover:text-primary transition duration-500 ease-in-out text-center mt-3"
                    >
                      {item.title}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-5 space-x-8">
              <button
                onClick={handlePrev}
                className="bg-gray-300 text-white w-12 h-12 rounded-full hover:bg-gray-400 transition duration-300 flex items-center justify-center focus:outline-none"
              >
                <FaArrowLeft />
              </button>
              <button
                onClick={handleNext}
                className="bg-gray-300 text-white w-12 h-12 rounded-full hover:bg-gray-400 transition duration-300 flex items-center justify-center focus:outline-none"
              >
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="py-8 sm:py-12">
          <div className="px-6 lg:px-8">
            <div
              className="mx-auto max-w-2xl text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h2 className="text-base font-semibold leading-7 text-primary">
                Discover
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                What We Are Renowned For
              </p>
              <p className="mt-6 lg:text-lg sm:text-md leading-8 text-gray-600">
                Discover our enduring legacy. We are known for our expertise in
                navigating immigration law, delivering exceptional service, and
                achieving successful outcomes for our clients.
              </p>
            </div>
            <div
              className="mx-auto max-w-7xl mt-16 sm:mt-20 lg:mt-24"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-16">
                    <dt className="text-base font-semibold leading-7 text-gray-900">
                      <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-2 text-base leading-7 text-gray-900">
                      {feature.description}
                    </dd>
                    <a
                      href="/about-us"
                      className="text-primary mt-2 inline-block"
                    >
                      Learn more &rarr;
                    </a>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section
        className="min-h-[70vh] mb-[50px] text-white mx-auto"
        id="header"
      >
        <section
          className="w-full min-h-[70vh] mt-[100px]"
          style={{
            backgroundImage:
              'url("https://res.cloudinary.com/dtnz1b8wq/image/upload/f_auto,q_auto/c_limit,w_1000/vqgcqkhu9fbwr7lh511g")',
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <div
            className="w-full slide-content-container mx-auto flex items-center justify-start py-8"
            style={{
              background: "rgba(7, 5, 5, 0.8)",
              height: "89vh",
            }}
          >
            <div className="container px-3 py-5 mx-auto min-h-[318px]">
              <section className="w-full flex flex-col gap-8 pl-[18px] border-white min-h-[318px] whitespace-normal lg:w-[881px]">
                <h2 className="elementor-heading-title elementor-size-default color">
                  Book Your Appointment with Us Today!
                </h2>
                <h3 className="text-gray-100 text-2xl">
                  We prioritize your needs and offer personalized solutions
                  tailored to your requirements.
                </h3>
                <div className="elementor-button-wrapper bg-main-color">
                  <a
                    className="elementor-button bg-primary elementor-button-link elementor-size-lg shadow-md"
                    href="/contact-us"
                  >
                    <span className="elementor-button-content-wrapper">
                      <span className="elementor-button-text">Contact Us</span>
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>



      <section>
        <div
          className="mx-auto max-w-2xl text-center mb-10"
          data-aos="fade-up"
          data-aos-duration="300"
        >
          <h2 className="text-base font-semibold leading-7 text-primary">
            FAQ
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Frequently asked questions
          </p>
        </div>
        <Faq />
      </section>
      <Footer />
    </div>
  );
};

export default Home;











      {/* 
      <div class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-white">
        <div class="grid grid-cols-2 row-gap-8 md:grid-cols-4 gap-12">
          <div class="text-center md:border-r border-primary">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">201+</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Successful casses
            </p>
          </div>
          <div class="text-center md:border-r border-primary">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">3K+</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Happy customers
            </p>
          </div>
          <div class="text-center md:border-r border-primary">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">2K+</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              Immigrations
            </p>
          </div>
          <div class="text-center">
            <h6 class="text-4xl font-bold lg:text-5xl xl:text-6xl">67+</h6>
            <p class="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
              {" "}
              consultations
            </p>
          </div>
        </div>
      </div> */}

      {/* <section className="py-3 bg-gradient-to-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div
            className="mx-auto max-w-2xl text-center"
            data-aos="fade-up"
            data-aos-duration="300"
          >
            <h2 className="text-base font-semibold leading-7 text-primary">
              Testimonials
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              We have worked with thousands of amazing people
            </p>
          </div>
          <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 auto-rows-auto">
            <div
              className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center col-span-1 row-span-2 md:col-span-2 md:row-span-1 lg:col-span-1"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                className="w-16 h-16 rounded-full object-cover mb-4"
                src={testimonials[0].imgSrc}
                alt={testimonials[0].name}
              />
              <p className="text-gray-700 text-center mb-4">
                {testimonials[0].text}
              </p>
              <div className="text-center">
                <p className="text-gray-900 font-bold">
                  {testimonials[0].name}
                </p>
                <p className="text-gray-500">{testimonials[0].username}</p>
              </div>
            </div>
            <div
              className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center col-span-1 row-span-1 md:col-span-2"
              data-aos="fade-up"
              data-aos-duration="1100"
            >
              <img
                className="w-16 h-16 rounded-full object-cover mb-4"
                src={testimonials[1].imgSrc}
                alt={testimonials[1].name}
              />
              <p className="text-gray-700 text-center mb-4">
                {testimonials[1].text}
              </p>
              <div className="text-center">
                <p className="text-gray-900 font-bold">
                  {testimonials[1].name}
                </p>
                <p className="text-gray-500">{testimonials[1].username}</p>
                {testimonials[1].company && (
                  <p className="text-gray-400 text-sm mt-1">
                    {testimonials[1].company}
                  </p>
                )}
              </div>
            </div>
            <div
              className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center col-span-1 row-span-1"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                className="w-16 h-16 rounded-full object-cover mb-4"
                src={testimonials[2].imgSrc}
                alt={testimonials[2].name}
              />
              <p className="text-gray-700 text-center mb-4">
                {testimonials[2].text}
              </p>
              <div className="text-center">
                <p className="text-gray-900 font-bold">
                  {testimonials[2].name}
                </p>
                <p className="text-gray-500">{testimonials[2].username}</p>
              </div>
            </div>
            <div
              className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center col-span-1 row-span-2"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img
                className="w-16 h-16 rounded-full object-cover mb-4"
                src={testimonials[3].imgSrc}
                alt={testimonials[3].name}
              />
              <p className="text-gray-700 text-center mb-4">
                {testimonials[3].text}
              </p>
              <div className="text-center">
                <p className="text-gray-900 font-bold">
                  {testimonials[3].name}
                </p>
                <p className="text-gray-500">{testimonials[3].username}</p>
              </div>
            </div>
            <div
              className="bg-white p-6 shadow-lg rounded-lg flex flex-col items-center col-span-1 row-span-3"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <img
                className="w-16 h-16 rounded-full object-cover mb-4"
                src={testimonials[4].imgSrc}
                alt={testimonials[4].name}
              />
              <p className="text-gray-700 text-center mb-4">
                {testimonials[4].text}
              </p>
              <div className="text-center">
                <p className="text-gray-900 font-bold">
                  {testimonials[4].name}
                </p>
                <p className="text-gray-500">{testimonials[4].username}</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}